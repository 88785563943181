import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import LandingPage from './landing-page/LandingPage';
import "@fortawesome/fontawesome-free/css/all.min.css"


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/docs" element={
            <iframe
              title="docs"
              src="https://app.theneo.io/breweries/beerapi"
              style={{ display: "block", width: "100%", height: "100vh" }}
              seamless
              frameBorder="0"
              scrolling="yes"
            />
          }
          />
        </Routes>

      </Router>
    </>
  );
}

export default App;
