import React from "react";
import '../App.css';

function Footer() {
  return (
    <>
      <div className="footer">
        <p>© 2022 Beer API. All rights reserved. </p>
        <a href="mailto:beer.api.team@gmail.com">Contact Us</a>
        {/* <p>Privacy · Terms · Sitemap · <Link to={"/locations"}>Locations</Link></p> */}
      </div>
    </>
  );
}

export default Footer;
