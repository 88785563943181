import { CBadge, CCol, CRow } from "@coreui/react";
import React from "react";

function HowItWorks() {
  return (
    <>
      <CRow className="p-5 text-center">
        <CCol>
          <h1>What is BeerAPI?</h1>
        </CCol>
      </CRow>
      <CRow className="p-5 text-center">
        <CCol className="px-lg-4 px-md-5 px-sm-5 px-5 pb-lg-0 pb-md-5 pb-sm-5 pb-5" lg={3} md={12}>
          <h1>
            <i className="fa fa-database" />
          </h1>
          <h4 className="my-lg-5">We have data.</h4>
          <p>The current dataset includes almost 10,000 breweries which are catagoriezed by type (nanobrewery, taphouse, etc)</p>
          <CBadge color="dark" shape="rounded-pill">1</CBadge>
        </CCol>
        <CCol className="px-lg-4 px-md-5 px-sm-5 px-5 py-lg-0 py-md-5 py-sm-5 py-5" lg={3} md={12}>
          <h1>
            <i className="fa fa-plus-circle" />
          </h1>
          <h4 className="my-lg-5">We (or you) add data.</h4>
          <p>We are constantly looking for new breweries to add to the dataset. If you find one we don't have yet, POST the data to submit for approval by our team.</p>
          <CBadge color="dark" shape="rounded-pill">2</CBadge>
        </CCol>
        <CCol className="px-lg-4 px-md-5 px-sm-5 px-5 py-lg-0 py-md-5 py-sm-5 py-5" lg={3} md={12}>
          <h1>
            <i className="fa fa-magnifying-glass" />
          </h1>
          <h4 className="my-lg-5">We check data.</h4>
          <p>No one likes showing up to a brewery that closed a month ago. We consistently validate the breweries and archive any that have closed/moved.</p>
          <CBadge color="dark" shape="rounded-pill">3</CBadge>
        </CCol>
        <CCol className="px-lg-4 px-md-5 px-sm-5 px-5 py-lg-0 py-md-5 py-sm-5 py-5" lg={3} md={12}>
          <h1>
            <i className="fa fa-face-smile" />
          </h1>
          <h4 className="my-lg-5">You use data.</h4>
          <p>This is the fun part! You make cool things with the data! The data has been used to build mobile apps, create Brewery Finder websites, and more!</p>
          <CBadge color="dark" shape="rounded-pill">4</CBadge>
        </CCol>
      </CRow>
    </>
  );
}

export default HowItWorks;

