import { CNavbar, CCollapse, CContainer, CNavbarNav, CNavbarToggler, CNavItem, CNavLink } from "@coreui/react";
import { useEffect, useState } from "react";
import "./Navbar.css";

function NavBar() {
  const [visible, setVisible] = useState(false)
  const [isTransparent, setIsTransparent] = useState(true)

  useEffect(() => window.addEventListener("scroll", handleScroll))

  const handleScroll = () => {
    if (window.pageYOffset > 70) {
      setIsTransparent(false)
    } else {
      setIsTransparent(true)
    }
  };

  return (
    <CNavbar expand="lg" colorScheme="dark" className={isTransparent ? "bg-transparent" : "bg-light"}>
      <CContainer fluid>
        {/* <CNavbarBrand href="/" className={isTransparent ? "text-white" : "text-secdarkondary"}>
          <CImage fluid width={140} src={icon} className="me-2 mb-1" />
        </CNavbarBrand> */}
        <CNavbarToggler onClick={() => setVisible(!visible)} />
        <CCollapse className="navbar-collapse justify-content-center" visible={visible}>
          <CNavbarNav>
            <CNavItem className="mx-3">
              <CNavLink href="/" active>
                Home
              </CNavLink>
            </CNavItem>
            <CNavItem className="mx-3">
              <CNavLink href="/docs">Docs</CNavLink>
            </CNavItem>
            <CNavItem className="mx-3">
              <CNavLink href="https://breweries.directus.app/admin/login">
                Sign In
              </CNavLink>
            </CNavItem>
          </CNavbarNav>
        </CCollapse>
      </CContainer>
    </CNavbar>
    // <CNavbar expand="lg" className={isTransparent ? "bg-transparent" : "bg-light"} placement="sticky-top">
    //   <CRow className="w-100 mx-5">
    //     <CCol lg={3} sm={3}>
    //       <CNavbarBrand href="/" className={isTransparent ? "text-white" : "text-secdarkondary"}>
    //         <CImage fluid width={140} src={icon} className="me-2 mb-1" />
    //       </CNavbarBrand>
    //     </CCol>
    //     <CCol lg={9} sm={6} className="px-5 d-lg-block d-md-none d-sm-none d-none my-auto">
    //       <CRow>
    //         <CCol xl={6} className="my-auto d-md-block d-sm-none d-none">
    //           <a className="" href="/vendors">
    //             <h5 className={`${isTransparent ? "text-white" : "text-dark"} float-end mb-0 fw-bold`}>
    //               Become A Vendor
    //             </h5>
    //           </a>
    //         </CCol>
    //         {/* <CCol className="my-auto ml-auto">
    //           <p className={`${isTransparent ? "text-white" : "text-dark"} float-end mb-0`}>Log In</p>
    //         </CCol> */}
    //         <CCol className="my-auto mx-md-auto float-sm-end float-end">
    //           <CButton
    //             color="secondary"
    //             href="tel:913-220-5546"
    //             className="float-end"
    //           >
    //             Call Us <i className="fa fa-phone" />
    //           </CButton>
    //         </CCol>
    //       </CRow>
    //     </CCol>
    //   </CRow>
    // </CNavbar>
  );
}

export default NavBar;
