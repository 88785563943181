import React from "react";
import { Link } from 'react-router-dom';
import "./banner.css";
import Navbar from '../navbar/Navbar';
import { CButton, CCol, CContainer, CRow } from "@coreui/react";

function Banner() {
  return (
    <CContainer fluid className="hero-image mx-0 px-0">
      <Navbar />
      <CRow className="h-75">
        <CCol className="px-5 py-5 mb-5 text-white mx-auto text-center" lg={6} md={8} sm={12}>
          <h1
            className="mb-0 mt-5 d-md-block d-sm-none d-none"
            style={{ fontSize: "80px" }}
          >
            <i className="fa fa-beer me-4" />
            +
            <i className="fa fa-database mx-4" />
            =
            <i className="fa fa-face-smile ms-4" />
          </h1>
          <h1
            className="mb-0 mt-5 d-md-none d-sm-block d-block"
          >
            <i className="fa fa-beer me-4" />
            +
            <i className="fa fa-database mx-4" />
            =
            <i className="fa fa-face-smile ms-4" />
          </h1>
          <h1
            className="mb-5"
            style={{ fontSize: "80px" }}
          >
            <b>The Beer API</b>
          </h1>
          <h3 className="text-warning mb-5">Community-driven, up-to-date brewery data</h3>
          <CButton color="light" href="https://buy.stripe.com/3cs4km6YmgMBepq7ss">
            <b>Get the Data</b>
            <i className="fa fa-chevron-right ms-2" />
          </CButton>
        </CCol>
      </CRow>
    </CContainer >
  );
}

export default Banner;
