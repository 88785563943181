import { CBadge, CButton, CCol, CRow } from "@coreui/react";
import React from "react";

function Pricing() {
  return (
    <CRow className="p-5 text-center">
      <CCol lg={5} sm={6} className="mx-auto">
        <h1 className="mb-3">Pricing</h1>
        <CButton variant="ghost" color="secondary" href="https://buy.stripe.com/3cs4km6YmgMBepq7ss">
          <h1 className="mb-0"><CBadge color="secondary">$7 / month</CBadge></h1>
        </CButton>
        <p>It just seems right for the monthly price to be about the cost of a beer!</p>
        <h4>What You Get</h4>
        <p><i className="fa fa-beer me-2" />Access to up-to-date global brewery data</p>
        <p><i className="fa fa-beer me-2" />Brewery location (including geo-coordinates)</p>
        <p><i className="fa fa-beer me-2" />Unique API key with no rate limiting</p>
        <p><i className="fa fa-beer me-2" />Data Portal to view, filter, and download data</p>
      </CCol>
    </CRow>
  );
}

export default Pricing;

