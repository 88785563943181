import React from "react";
import { CRow, CCol } from "@coreui/react";
import Banner from "./banner/Banner";
import Footer from "../common/Footer";
import HowItWorksCustomer from "./how-it-works/HowItWorks";
import DataStats from "./data-stats/DataStats";
import Pricing from "./pricing/Pricing";

function LandingPage() {
  return (
    <>
      <Banner />
      <HowItWorksCustomer />
      <DataStats />
      <Pricing />
      <CRow className="p-5 bg-warning">
        <CCol className="text-center mx-auto" lg={6} sm={6}>
          <h2>Our Mission</h2>
          <p>
            To provide a community-driven, verified dataset of breweries, taphouses, and pubs accessible to developers and analysts.
          </p>
          <p>All for the love of beer!</p>
        </CCol>
      </CRow>
      <Footer />
    </>
  );
}

export default LandingPage;

