import { CCol, CRow } from "@coreui/react";
import React from "react";

function DataStats() {
  return (
    <>
      <CRow className="p-5 text-center bg-warning">
        <CCol>
          <h1>The Data</h1>
        </CCol>
      </CRow>
      <CRow className="p-5 text-center bg-warning">
        <CCol>
          <h1>
            <i className="fa fa-beer" />
          </h1>
          <h4>9,000+</h4>
          <p>Breweries Globally</p>
          <p><i>(and growing)</i></p>
        </CCol>
        <CCol>
          <h1>
            <i className="fa fa-map" />
          </h1>
          <h4>500+</h4>
          <p>Cities Represented</p>
        </CCol>
        <CCol>
          <h1>
            <i className="fa fa-filter" />
          </h1>
          <h4>10</h4>
          <p>Brewery Types</p>
          <p><i>(Taphouse, Nanobrewery, etc)</i></p>
        </CCol>
      </CRow>
    </>
  );
}

export default DataStats;

